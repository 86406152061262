body {
  margin: 0;
  font-family: poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.shadow-primary {
  box-shadow: 11px 12px 28px 2px rgba(0,0,0,0.75);
-webkit-box-shadow: 11px 12px 28px 2px rgba(0,0,0,0.75);
-moz-box-shadow: 11px 12px 28px 2px rgba(0,0,0,0.75);
}

.element-center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.centering {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
