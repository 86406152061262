
.hamburger-line {
    @apply w-[30px] h-[2.5px] bg-text rounded-lg my-2 block
}

#hamburger-menu-active > span:nth-child(1) {
    @apply rotate-45
}
#hamburger-menu-active > span:nth-child(2) {
    @apply scale-0
}
#hamburger-menu-active > span:nth-child(3) {
    @apply -rotate-45
}

#drawer-true{
    @apply left-0
}

#drawer-false {
    @apply -left-full
}



.hamburger-line {
    @apply w-[30px] h-[2.5px] bg-text rounded-lg my-2 block
}

#hamburger-menu-active > span:nth-child(1) {
    @apply rotate-45
}
#hamburger-menu-active > span:nth-child(2) {
    @apply scale-0
}
#hamburger-menu-active > span:nth-child(3) {
    @apply -rotate-45
}

#drawer-true{
    @apply left-0
}

#drawer-false {
    @apply -left-full
}


